import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import Metadata from "components/Metadata"
import SimpleHero from "components/blocks/SimpleHero"
import JobListings from "components/blocks/JobListings"
import IntroText from "components/blocks/IntroText"
import ContentBlock from "components/ContentBlock"
import Button from "components/Button"
import theme from "styles/theme"
import useTranslate from "hooks/useTranslate"

const JobPost = ({
  data: {
    jobPost: { title, location, department, applyLink, description, metadata },
  },
  location: { pathname: currentPath },
}) => {
  const strings = useTranslate({
    title: "Careers at Secondmind",
    apply: "Apply for this position",
    listingsTitle: "Current vacancies",
    location: "Location",
    department: "Department",
  })

  return (
    <Layout mode="light" currentPath={currentPath}>
      <Metadata {...metadata} />

      <SimpleHero
        image={{
          asset: {
            _id: "image-9a85bc35cdf3c549854f72e0f57303f577e455e1-2880x2880-png",
          },
        }}
      />

      <IntroText text={strings.title} />

      <div css={theme.expandQueries({ padding: ["104px 25px", "56px 25px"] })}>
        <h1 css={{ ...theme.h1, textAlign: "center" }}>{title}</h1>

        <div css={theme.expandQueries({ maxWidth: "36em", margin: "0 auto" })}>
          <div css={{ ...theme.p3, marginTop: 32, textAlign: "center" }}>
            <div>
              <strong>{strings.location}:</strong> {location.name}
            </div>
            <div>
              <strong>{strings.department}:</strong> {department.name}
            </div>
          </div>

          <ContentBlock
            content={description}
            blockOverrides={{
              /* eslint-disable react/display-name, react/prop-types */
              normal: (props) => <p {...props} />,
              h2: (props) => (
                <h2
                  css={{ ...theme.h3, opacity: 1, marginTop: "2em" }}
                  {...props}
                />
              ),
              h3: (props) => (
                <h3
                  css={{ ...theme.h4, opacity: 1, marginTop: "2em" }}
                  {...props}
                />
              ),
              /* eslint-enable react/display-name, react/prop-types */
            }}
            css={theme.expandQueries({
              marginTop: [56, 32],
            })}
          />

          <div css={{ marginTop: 32 }}>
            <Button href={applyLink} target="_blank" rel="noopener noreferrer">
              {strings.apply}
            </Button>
          </div>
        </div>
      </div>

      <div css={{ background: theme.colors.accentBackground }}>
        <JobListings title={strings.listingsTitle} />
      </div>
    </Layout>
  )
}

export default JobPost

JobPost.propTypes = {
  data: PropTypes.shape({
    jobPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      applyLink: PropTypes.string.isRequired,
      location: PropTypes.shape({ name: PropTypes.string.isRequired })
        .isRequired,
      department: PropTypes.shape({ name: PropTypes.string.isRequired })
        .isRequired,
      description: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      metadata: PropTypes.shape(Metadata.propTypes).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query JobPostQuery($id: String!) {
    jobPost: sanityJobPost(_id: { eq: $id }) {
      title
      location {
        name
      }
      department {
        name
      }
      applyLink
      description: _rawDescription
      metadata {
        ...Metadata
      }
    }
  }
`
